<form class="input-group" (submit)="emailField.valid && onAddUser()">
  <input ngModel [value]="email" name="emailField" #emailField="ngModel"
         id="emailField" class="form-control form-control-sm" type="email"
         i18n-placeholder="@@user.add.placeholder"
         inputmode="email"
         placeholder="Type email to invite another user"
         (input)="onInputChanged(emailField.control.value)" email>
  <div class="input-group-append">
    <button type="button"
            class="btn btn-sm btn-primary"
            [disabled]="emailField.invalid || !email || isDuplicate"
            (click)="onAddUser()">
      <i class="fas fa-plus me-2"></i>
      <span class="fw-bold" i18n="@@users.add.button">Invite user</span>
    </button>
  </div>
</form>
<small id="emailValid" class="invalid-message" *ngIf="emailField.invalid && emailField.touched"
       i18n="@@users.user.email.invalid">
  Please enter a valid e-mail address (e.g. alan.turing&#64;lumeer.io).
</small>
<small class="invalid-message" *ngIf="emailField.valid && isDuplicate" i18n="@@users.user.email.duplicate">
  This user email is already invited. Try another one!
</small>
