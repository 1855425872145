<top-panel-wrapper>
  <div *ngIf="{collection: collection$ | async, permissions: permissions$ | async} as data"
       class="col-12 col-lg-11 col-xl-10 container-fluid mx-auto content">
    <resource-header
      [resource]="data.collection"
      [resourceType]="collectionType"
      [permissions]="permissions$ | async"
      (back)="onBack()"
      (delete)="onDelete()"
      (colorIconChange)="onNewColorOrIcon($event)"
      (descriptionChange)="onNewDescription($event)"
      (nameChange)="onNewName($event)">

      <div class="d-flex flex-row text-primary flex-nowrap align-items-center me-3"
           title="Number of users" i18n-title="@@collection.settings.users.count">
        <i class="far fa-user" aria-hidden="true"></i>&nbsp;<span>{{ userCount$ | async }}</span>
      </div>

      <div class="d-flex flex-row text-success flex-nowrap align-items-center cursor-pointer color-success me-3"
           (click)="onDocumentsClick()"
           title="Number of records" i18n-title="@@collection.settings.records.count">
        <i class="far fa-file" aria-hidden="true"></i>&nbsp;<span>{{ data.collection.documentsCount }}</span>
      </div>

      <collection-download *ngIf="data.permissions?.roles?.DataRead"
                            [collection]="data.collection"
                           class="d-block me-3">
      </collection-download>

      <collection-upload *ngIf="data.permissions?.roles?.DataContribute"
                         class="d-block me-3"
                         [collection]="data.collection">
      </collection-upload>

    </resource-header>

    <div class="d-flex flex-row-reverse text-end mb-3" *ngIf="(organizationAndProject$ | async) as realWorkspace">
      <copy-text-box [label]="tableIdLabel"
                     [value]="realWorkspace.organization?.id + '/' + realWorkspace.project?.id + '/' + data.collection.id"></copy-text-box>
    </div>

    <ul class="nav nav-tabs px-5">
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="['purpose']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.workflow">
          Workflow
        </a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.AttributeEdit">
        <a [routerLink]="['attributes']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.attributes">
          Attributes
        </a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.TechConfig">
        <a [routerLink]="['rules']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.automation">
          Automation
        </a>
      </li>
      <li role="presentation" class="nav-item">
        <a [routerLink]="['linktypes']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.link.types">
          Link Types
        </a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.UserConfig">
        <a [routerLink]="['users']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.permissions">
          Users
        </a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.UserConfig">
        <a [routerLink]="'teams'"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.teams">Teams</a>
      </li>
      <li role="presentation" class="nav-item" *ngIf="data.permissions?.roles?.Manage">
        <a [routerLink]="['activity']"
           routerLinkActive="active"
           class="nav-link"
           i18n="@@collection.config.tab.activity">
          Activity
        </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</top-panel-wrapper>
